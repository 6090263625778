<template>
  <div class="OrderTable bg-white p-10">
    <div class="orderTable" style="padding: 20px 0">
      <!-- 条件  -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="10">
            <a-col :md="8" :sm="24">
              <a-form-item label="订单号">
                <a-input v-model="queryParam.id" placeholder="请输入订单号" size="small" />
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-range-picker
                ref="rangePicker"
                v-model="selectTime"
                :ranges="{ '今天': [moment(), moment()], '本月': [moment().startOf('month'), moment().endOf('month')] }"
                size="small"
                @change="rangePickerOnChange" />
            </a-col>
            <a-col :md="!advanced && 8 || 8" :sm="24">
              <span
                :style="advanced && { float: 'right', overflow: 'hidden' } || {}"
                class="table-page-search-submitButtons">
                <a-button size="small" type="primary" @click="$refs.table.refresh(true)">查询</a-button>
                <a-button
                  size="small"
                  style="margin-left: 8px"
                  @click="() => { this.queryParam = {} }">重置</a-button>
                <a-button
                  size="small"
                  style="margin-left: 8px"
                  @click="DropdownOnClick()">
                  <a-icon type="build" />
                  批量代发
                </a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <s-table
        ref="table"
        :alert="true"
        :columns="columns"
        :data="loadData"
        :defaultExpandAllRows="true"
        :pagination="{ pageSizeOptions: ['10', '20', '30', '40', '100', '500']}"
        :rowSelection="rowSelection"
        :scroll="{ x: 900, y: 600 }"
        rowKey="id"
        showPagination="auto"
        size="middle">
        <div slot="description" slot-scope="text, record">
          <div class="td-box">
            <div class="td-mt">
              <span class="text-gary verdana">{{ record.createTime }}</span>
              <span class="text-gary ml-30">订单号：</span>
              <span class="verdana">{{ record.id }}</span>
            </div>
            <div class="td-mc" style="border-left: 1px solid #e5e5e5">
              <span class="flex-row flex-col-center">
                <div
                  class="flex-row flex-col-center"
                  style="width: 320px;cursor: pointer"
                  @click="$router.push({ path: '/commodity/' + record.productId })">
                  <img :src="record.imgUrl" height="80" style="padding: 5px" width="80">
                  <div class=" flex-column flex-row-between" style="padding: 5px">
                    <Ellipsis :length="48" tooltip>{{ record.productName }}</Ellipsis> <br>
                    <Ellipsis :length="24" style="margin-top: auto" tooltip> {{ record.specValues }} </Ellipsis>
                  </div>
                </div>
                <span class="text-gary" style="margin-left: 75px;"> X{{ record.quantity }}</span>
              </span>
            </div>
          </div>
        </div>
        <div slot="userShopInfo" slot-scope="text, record">
          <div class="td-mt">
            <span class="text-gary">名称：</span>
            <span class="verdana">{{ record.userName }}</span>
          </div>
          <div class="td-mt">
            <span class="text-gary">号码：</span>
            <span class="verdana">{{ record.telNum }}</span>
          </div>
          <div class="td-mt">
            <span class="text-gary">收货地址：</span>
            <span class="verdana">{{ `${record.provinceName} ${record.cityName} ${record.countyName} ${record.detailInfo}` }}</span>
          </div>
        </div>
        <div slot="salesPrice" slot-scope="text, record">
          <div class="td-box">
            <div class="td-mt"></div>
            <div class="td-mc">
              <div v-for="(supplyOrderItem, index) in record.supplyOrderItem" :key="supplyOrderItem.id + '-' + index">
                总价：<span style="color: #ff0000;font-weight: bold;">￥{{ Number((supplyOrderItem.salesPrice * supplyOrderItem.quantity) + record.freightPrice).toFixed(2) }}</span>
              </div>
            </div>
          </div>
        </div>
        <div slot="orderLogistics" slot-scope="text, record">
          <div class="td-box">
            <div class="td-mt"></div>
            <div class="td-mc">
              <p>{{ record.orderLogistics.userName }}</p>
              <p>{{ record.orderLogistics.telNum }}</p>
            </div>
          </div>
        </div>
        <!--        订单流程状态展示-->
        <span slot="applyState" slot-scope="text, record">
          <span class="td-box">
            <span class="td-mt"></span>
            <span class="td-mc">
              <template >
                <a-badge :status="text | statusTypeFilter" :text="text | statusFilter" class="text-gary"/>
              </template>
            </span>
          </span>
        </span>
        <span slot="action" slot-scope="text, record">
          <div class="td-box">
            <div class="td-mt"></div>
            <div class="td-mc" style="border-right: 1px solid #e5e5e5">
              <template>
                <a-button
                  v-if="[0, 2].includes(record.applyState) && record.userStatus !== 5"
                  size="small"
                  type="link"
                  @click="dropFn(record)">{{ record.applyState == 0 ? '一键代发' : '代发重试' }}</a-button>
                <a-button v-if="[0, 2].includes(record.applyState)" size="small" type="link" @click="cancelOrder(record)">取消代发订单</a-button>
              </template>
            </div>
          </div>
        </span>
      </s-table>
      <el-dialog
        :close-on-click-modal="false"
        :visible.sync="dialogVisible"
        title="提示"
        width="65%">
        <div style="margin-bottom: 15px;">
          <span class="ss">成功订单数量：{{ createOrder.successCount }}</span>
          <span class="ss">失败订单数量：{{ createOrder.failOrders ? createOrder.failOrders.length : '0' }}</span>
          <span class="ss">总金额：{{ createOrder.amountSum || '0' }}</span>
        </div>
        <el-table
          v-if="isBoole"
          :data="createOrder.failOrders"
          border
          style="width: 100%">
          <el-table-column
            label="订单号"
            prop="id"
            width="180">
          </el-table-column>
          <el-table-column
            label="商品名称"
            prop="productName"
            width="180">
            <template slot-scope="scope">
              <Ellipsis :length="40">{{ scope.row.productName }}</Ellipsis>
            </template>
          </el-table-column>
          <el-table-column
            label="收货人"
            prop="userName">
          </el-table-column>
          <el-table-column
            label="手机号"
            prop="telNum">
          </el-table-column>
        </el-table>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button v-if="createOrder.successCount" type="primary" @click="goFn">去 支 付</el-button>
         </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
/**
 * @author  LiJianZhong
 * @date  2023/05/17 10:00
 * @version 1.0
 * @description 商城用户代发订单
 */

import moment from 'moment'
import STable from '@/components/Table'
import Ellipsis from '@/components/Ellipsis'
import {getCancelShipOrder, getShipCreateOrder, getShipPageOrderList} from '@/api/Order'
import { DropStatusMap } from '@/Map/OrderStatusMap'
import { const2 } from '@/views/User/Order/OrderTable/OrderTableColumns'
import { mapGetters } from 'vuex'

const statusMap = DropStatusMap

export default {
  name: 'DropOrder',
  components: {
    STable,
    Ellipsis,
  },
  watch: {
    queryParam: {
      deep: true,
      handler: function (newValue, oldValue) {
        this.$refs.table.refresh(true)
      }
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'infoProfect']),
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange
      }
    },
  },
  filters: {
    statusFilter(type) {
      return statusMap[type].text
    },
    statusTypeFilter(type) {
      return statusMap[type].status
    },
  },
  data() {
    return {
      isBoole: false,
      createOrder: {},
      dialogVisible: false,
      selectedRowKeys: [],  // 勾选获取的值
      selectedRows: [],   // 勾选获取对应的对象
      selectTime: [],
      moment,
      // table字段
      columns: const2,
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {
        descs: 'create_time'
      },
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const that = this
        const requestParameters = Object.assign({}, parameter, that.queryParam)
        if (Object.keys(that.userInfo).length > 0 && that.infoProfect === 1) {
          return getShipPageOrderList(requestParameters)
            .then(res => {
              console.log('res订单列表:', res)
              return res.data
            })
        }
      },
    }
  },

  created() {},
  methods: {
    onSelectChange(selectedRowKeys, selectedRows) {
      console.log('勾选', selectedRowKeys, selectedRows)
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    /**
     * 批量代发
     * @param record
     */
    DropdownOnClick() {
      if (this.selectedRows.length <= 0) return this.$message.warning('请选择批量付款的代发订单')
      this.$refs.table.localLoading = true
      getShipCreateOrder(this.selectedRowKeys).then(res => {
        console.log('批量代发', res.data)
        if (Object.keys(res.data).length) {
          this.isBoole = res.data.failOrders.length > 0 ? true : false
          this.$refs.table.localLoading = false
          this.createOrder = res.data
          this.dialogVisible = true
        }
      })
    },
    // 去支付
    goFn() {
      this.$router.push({
        path: '/user/order',
        query: {
          'status': 0,
          'array': JSON.stringify(this.createOrder.successOrderNos)
        }
      })
    },
    /**
     * 代发
     * @param record
     */
    dropFn(record) {
      this.$refs.table.localLoading = true
      let id = [record.id]
      getShipCreateOrder(id).then(res => {
        console.log('代发', res)
        this.$refs.table.localLoading = false
        this.$router.push(`/user/order/detail/${record.id}`)
      })
    },
    /**
     * 点击取消订单
     * @param record
     */
    cancelOrder(record) {
      let id = record.id
      let that = this
      this.$confirm('您确定要取消代发订单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
          getCancelShipOrder({id}).then(res => {
            this.$message.success('取消成功')
            that.$refs.table.refresh(true)
          })
      }).catch(() => {
        console.log('取消')
      })
    },
    /**
     * 日期选择器回调
     * @param dates
     * @param dateStrings
     */
    rangePickerOnChange(dates, dateStrings) {
      this.queryParam.startTime = dateStrings[0]
      this.queryParam.endTime = dateStrings[1]
    },
  },
}
</script>

<style lang="scss" scoped>
.ss {
  padding-left: 10px;
  padding-right: 20px;
  font-size: 17px;
}
::v-deep .ant-table {
  font-size: 12px;
  color: #000;
}

.text-gary {
  color: #aaa
}
</style>
